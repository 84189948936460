import { Box, Typography } from '@mui/material'
import IntlMessages from '@/helpers/IntlMessages'
import { Fonts } from '@/constants/AppEnums'
import ChangePasswordForm from './ChangePasswordForm'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useAuthUser } from '@/hooks'
import { useUserApi } from '@/hooks/KrazzyApi/useUserApi'
import AppInfoView from '@/components/lib/AppInfoView'

const validationSchema = yup.object({
  oldPassword: yup
    .string()
    .required('No password provided.')
    .min(6, 'Password is too short - should be 8 chars minimum.'),
  // .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
  newPassword: yup
    .string()
    .required('No password provided.')
    .min(6, 'Password is too short - should be 8 chars minimum.'),
  // .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
  retypeNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), undefined], 'Passwords must match'),
})

const ChangePassword = () => {
  const { user } = useAuthUser()
  const { updateUserPassword } = useUserApi()

  const upDatePassword = (data: any) => {
    const newData = {
      ...data,
      uid: user.uid,
    }
    updateUserPassword(newData)
  }

  return (
    <Box
      sx={{
        position: 'relative',
        maxWidth: 550,
      }}
    >
      <Typography
        component="h3"
        sx={{
          fontSize: 16,
          fontWeight: Fonts.BOLD,
          mb: { xs: 3, lg: 5 },
        }}
      >
        <IntlMessages id="common.changePassword" />
      </Typography>
      <Formik
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={{
          oldPassword: '',
          newPassword: null,
          retypeNewPassword: 'us',
        }}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting }) => {
          setSubmitting(true)
          upDatePassword(data)
          setSubmitting(false)
        }}
      >
        {() => <ChangePasswordForm />}
      </Formik>
      <AppInfoView />
    </Box>
  )
}

export default ChangePassword
