import React from 'react'
import AppSuspense from '../AppSuspense'
import AppErrorBoundary from '../AppErrorBoundary'
import AppContentViewWrapper from './AppContentViewWrapper'
import { SxProps } from '@mui/system'

type AppContentViewProps = {
  sxStyle?: SxProps
  routes: React.ReactElement | null
}

const AppContentView: React.FC<AppContentViewProps> = ({ routes }) => {
  return (
    <AppContentViewWrapper>
      <AppSuspense>
        <AppErrorBoundary>{routes}</AppErrorBoundary>
      </AppSuspense>
    </AppContentViewWrapper>
  )
}

export default AppContentView
