import React from 'react'

const Error401 = React.lazy(() => import('../../modules/errorPages/error401'))
const Error403 = React.lazy(() => import('../../modules/errorPages/error403'))
const Error404 = React.lazy(() => import('../../modules/errorPages/error404'))
const Error500 = React.lazy(() => import('../../modules/errorPages/error500'))
const Error503 = React.lazy(() => import('../../modules/errorPages/error503'))
const ComingSoon = React.lazy(
  () => import('../../modules/errorPages/comingSoon')
)
const Maintenance = React.lazy(
  () => import('../../modules/errorPages/maintenance')
)

export const errorPagesConfigs = [
  {
    path: '/error-401',
    element: <Error401 />,
  },
  {
    path: '/error-403',
    element: <Error403 />,
  },
  {
    path: '/error-404',
    element: <Error404 />,
  },
  {
    path: '/error-500',
    element: <Error500 />,
  },
  {
    path: '/error-503',
    element: <Error503 />,
  },
  {
    path: '/coming-soon',
    element: <ComingSoon />,
  },
  {
    path: '/maintenance',
    element: <Maintenance />,
  },
]
