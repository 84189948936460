import React, { ReactNode } from 'react'

type AppContentViewWrapperProps = {
  children: ReactNode
}

const AppContentViewWrapper: React.FC<AppContentViewWrapperProps> = ({
  children,
}) => {
  return <>{children}</>
}

export default AppContentViewWrapper
