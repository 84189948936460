import React, { useEffect, useState } from 'react'
import { alpha, Box, Button, Typography } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import AppGridContainer from '@/components/lib/AppGridContainer'
import Grid from '@mui/material/Grid'
import IntlMessages from '@/helpers/IntlMessages'
import { useDropzone } from 'react-dropzone'
import { Form } from 'formik'
import AppTextField from '@/components/lib/AppFormComponents/AppTextField'
import EditIcon from '@mui/icons-material/Edit'
import { styled } from '@mui/material/styles'
import { Fonts } from '@/constants/AppEnums'
import { storage } from '@/services/auth/firebase/firebase'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { v4 as uuidv4 } from 'uuid'
import { useUserApi } from '@/hooks/KrazzyApi/useUserApi'
import { User } from '~/modules/adminPages/@types/getuser'

const AvatarViewWrapper = styled('div')(({ theme }) => {
  return {
    position: 'relative',
    cursor: 'pointer',
    '& .edit-icon': {
      position: 'absolute',
      bottom: 0,
      right: 0,
      zIndex: 1,
      border: `solid 2px ${theme.palette.background.paper}`,
      backgroundColor: alpha(theme.palette.primary.main, 0.7),
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
      width: 26,
      height: 26,
      display: 'none',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'all 0.4s ease',
      cursor: 'pointer',
      '& .MuiSvgIcon-root': {
        fontSize: 16,
      },
    },
    '&.dropzone': {
      outline: 0,
      '&:hover .edit-icon, &:focus .edit-icon': {
        display: 'flex',
      },
    },
  }
})

type PersonalInfoFormProps = {
  setFieldValue: (field: string, data: any) => void
  values: any
}

const PersonalInfoForm: React.FC<PersonalInfoFormProps> = ({
  values,
  setFieldValue,
}) => {
  const [userData, setUserData] = useState<User>()
  const { getUserById } = useUserApi()

  const user = async () => {
    const data = await getUserById()
    setUserData(data as User)
  }

  useEffect(() => {
    user()
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/png': ['.png', '.jpg', '.jpeg'],
    },
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles != null) {
        const imageRef = ref(
          storage,
          `/profilePicture/${values.uid}/${uuidv4()}-${acceptedFiles![0].name}`
        )
        try {
          const snapshot = await uploadBytes(imageRef, acceptedFiles![0])
          alert('Image Uploaded Successfully')

          const url = await getDownloadURL(snapshot.ref)
          setFieldValue('photoURL', url)
        } catch (error: any) {
          alert(error)
        }
      }
    },
  })

  return (
    <Form noValidate autoComplete="off">
      <Typography
        component="h3"
        sx={{
          fontSize: 16,
          fontWeight: Fonts.BOLD,
          mb: { xs: 3, lg: 4 },
        }}
      >
        <IntlMessages id="common.personalInfo" />
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: { xs: 5, lg: 6 },
        }}
      >
        <AvatarViewWrapper {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <label htmlFor="icon-button-file">
            <Avatar
              sx={{
                width: { xs: 50, lg: 64 },
                height: { xs: 50, lg: 64 },
                cursor: 'pointer',
              }}
              src={values.photoURL}
            />
            <Box className="edit-icon">
              <EditIcon />
            </Box>
          </label>
        </AvatarViewWrapper>
        <Box
          sx={{
            ml: 4,
          }}
          display="flex"
        >
          <Box
            sx={{
              ml: 4,
            }}
          >
            <Typography
              sx={{
                fontWeight: Fonts.MEDIUM,
              }}
            >
              {values.displayName}
            </Typography>
            <Typography
              sx={{
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              {values.email}
            </Typography>
          </Box>
          <Box
            sx={{
              ml: 4,
            }}
          >
            <Typography
              sx={{
                fontWeight: Fonts.MEDIUM,
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              <span style={{ color: '#707070' }}>Address : </span>
              {userData?.address}
            </Typography>
            <Typography
              sx={{
                fontWeight: Fonts.MEDIUM,
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              <span style={{ color: '#707070' }}>Contact number : </span>
              {userData?.phone}
            </Typography>
          </Box>
        </Box>
      </Box>
      <AppGridContainer spacing={4}>
        <Grid item xs={12} md={6}>
          <AppTextField
            name="displayName"
            fullWidth
            label={<IntlMessages id="common.fullName" />}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AppTextField name="address" fullWidth label="Address" />
        </Grid>
        <Grid item xs={12} md={6}>
          <AppTextField name="phone" fullWidth label="Contact Number" />
        </Grid>

        <Grid item xs={12} md={12}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              sx={{
                position: 'relative',
                minWidth: 100,
              }}
              color="primary"
              variant="contained"
              type="submit"
            >
              <IntlMessages id="common.saveChanges" />
            </Button>
            <Button
              sx={{
                position: 'relative',
                minWidth: 100,
                ml: 2.5,
              }}
              color="primary"
              variant="outlined"
            >
              <IntlMessages id="common.cancel" />
            </Button>
          </Box>
        </Grid>
      </AppGridContainer>
    </Form>
  )
}

export default PersonalInfoForm
