import { FirebaseError } from 'firebase/app'
import {
  ApplicationData,
  WelcomePageData,
} from '~/modules/adminPages/@types/app'
import { apiHelperFunctions } from './apiHelperFunctions'

export const useApplicationData = () => {
  const { api } = apiHelperFunctions()

  async function getApplicationData(): Promise<ApplicationData> {
    try {
      const requestOptions: RequestInit = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const data = await api<WelcomePageData>(
        `${process.env.REACT_APP_API_URL}/appdata`,
        requestOptions
      )

      return {
        welcomePage: data,
      }
    } catch (error) {
      if (error instanceof FirebaseError) {
        throw error
      } else {
        throw error
      }
    }
  }

  return { getApplicationData }
}
