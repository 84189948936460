import React, { useEffect } from 'react'
import { useUrlSearchParams } from 'use-url-search-params'
import AppContentView from '@/components/lib/AppContentView'
import generateRoutes from '@/helpers/RouteGenerator'
import { Layouts } from '@/components/lib/AppLayout'
import { useAuthUser } from '@/hooks/AuthHooks'
import {
  useLayoutActionsContext,
  useLayoutContext,
} from '@/context/AppContextProvider/LayoutContextProvider'
import { useSidebarActionsContext } from '@/context/AppContextProvider/SidebarContextProvider'
import {
  anonymousStructure,
  authorizedStructure,
  unAuthorizedStructure,
} from '../AppRoutes'
import { useRoutes } from 'react-router-dom'
import routesConfig from '../AppRoutes/routeconfig'
import { isAdmin } from '@/helpers/RouteHelper'
import Error404 from '~/modules/errorPages/error404'
import { useAppDataContext } from '@/context/AppDataContext/AppDataContextProvider'
import AppLoader from '@/components/lib/AppLoader'

const AppLayout = () => {
  const { navStyle } = useLayoutContext()

  const { user, isAuthenticated } = useAuthUser()
  const { updateNavStyle } = useLayoutActionsContext()
  const { updateMenuStyle, setSidebarBgImage } = useSidebarActionsContext()
  const { isApplicationDataLoaded, isError } = useAppDataContext()
  const AppLayout = Layouts[navStyle]
  const [params] = useUrlSearchParams()
  const generatedRoutes = generateRoutes({
    isAuthenticated: isAuthenticated,
    userRole: user?.role,
    unAuthorizedStructure,
    authorizedStructure,
    anonymousStructure,
  })
  const routes = useRoutes(generatedRoutes)

  useEffect(() => {
    if (params.layout) updateNavStyle(params.layout as string)
    if (params.menuStyle) updateMenuStyle(params.menuStyle as string)
    if (params.sidebarImage) setSidebarBgImage(true)
  }, [params, setSidebarBgImage, updateNavStyle, updateMenuStyle])

  return (
    <>
      {!isError ? (
        <>
          {isApplicationDataLoaded ? (
            <>
              {isAdmin(user?.role) ? (
                <AppLayout routes={routes} routesConfig={routesConfig} />
              ) : (
                <AppContentView routes={routes} />
              )}
            </>
          ) : (
            <AppLoader />
          )}
        </>
      ) : (
        <Error404 />
      )}
    </>
  )
}

export default AppLayout
