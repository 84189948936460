import { BrowserRouter } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import AppContextProvider from '@/context/AppContextProvider'
import AppThemeProvider from '@/context/AppThemeProvider'
import AppDataProvider from '@/context/AppDataContext/AppDataContextProvider'
import AppStyleProvider from '@/context/AppStyleProvider'
import AppLocaleProvider from '@/context/AppLocaleProvider'
import InfoViewContextProvider from '@/context/AppContextProvider/InfoViewContextProvider'
import AuthRoutes from '@/components/lib/AuthRoutes'
import AppLayout from './core/AppLayout'
import AppAuthProvider from './core/AppAuthProvider'
import '@/mockapi'
import './assets/styles/maps.css'

import './assets/css/bootstrap.min.css'
import './assets/css/icofont.min.css'
import './assets/css/animate.min.css'
import '../node_modules/react-modal-video/css/modal-video.min.css'
import './assets/css/style.css'
import './assets/css/responsive.css'
import 'react-image-gallery/styles/css/image-gallery.css'

const App = () => (
  <AppContextProvider>
    <AppThemeProvider>
      <AppStyleProvider>
        <AppLocaleProvider>
          <BrowserRouter>
            <InfoViewContextProvider>
              <AppAuthProvider>
                <AuthRoutes>
                  <AppDataProvider>
                    <CssBaseline />
                    <AppLayout />
                  </AppDataProvider>
                </AuthRoutes>
              </AppAuthProvider>
            </InfoViewContextProvider>
          </BrowserRouter>
        </AppLocaleProvider>
      </AppStyleProvider>
    </AppThemeProvider>
  </AppContextProvider>
)

export default App
