import React, {
  createContext,
  ReactNode,
  useEffect,
  useContext,
  useReducer,
} from 'react'
import { contextReducer, AppDataActions } from './AppDataReducer'
import { ApplicationData } from '~/modules/adminPages/@types/app'
import { useApplicationData } from '@/hooks/KrazzyApi/useApplicationData'

export const ContextState: ApplicationData = {
  isApplicationDataLoaded: false,
  isError: false,
}

const AppDataContext = createContext<ApplicationData>(ContextState)

export const useAppDataContext = () => useContext(AppDataContext)

type AppDataContextProviderProps = {
  children: ReactNode
}
const AppDataProvider: React.FC<AppDataContextProviderProps> = (props) => {
  const [state, dispatch] = useReducer(
    contextReducer,
    ContextState,
    () => ContextState
  )
  const { getApplicationData } = useApplicationData()

  const loadData = async () => {
    try {
      const data = await getApplicationData()
      dispatch({
        type: AppDataActions.LOAD_APPLICATION_DATA_SUCCESS,
        payload: { welcomePage: data.welcomePage },
      })
    } catch (error) {
      dispatch({ type: AppDataActions.LOAD_APPLICATION_DATA_FAIL })
    }
  }

  useEffect(() => {
    if (!state.isApplicationDataLoaded) {
      void loadData()
    }
  }, [])

  return (
    <AppDataContext.Provider value={state}>
      {/*<AppDataActionsContext.Provider*/}
      {/*  value={{*/}
      {/*    loadApplicationData,*/}
      {/*  }}*/}
      {/*>*/}
      {props.children}
      {/*</AppDataActionsContext.Provider>*/}
    </AppDataContext.Provider>
  )
}

export default AppDataProvider
