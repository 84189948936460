import { itIT } from '@mui/material/locale'
import saMessages from '../locales/it_IT.json'

const saLang = {
  messages: {
    ...saMessages,
  },
  muiLocale: itIT,
  locale: 'it-IT',
}
export default saLang
