import Cta from '~/modules/consumerPortal/common/Cta'
import lax from 'lax.js'
import IntlMessages from '@/helpers/IntlMessages'
import { useEffect } from 'react'

function WhyUs({ whyChoosUs }: any) {
  useEffect(() => {
    lax.setup()
    const handleScroll = () => {
      lax.update(window.scrollY)
    }

    document.addEventListener('scroll', handleScroll, true)

    lax.update(window.scrollY)

    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return (
    <>
      <section className="why-choose-us-two">
        <div className="row m-0 h-100 align-items-center">
          <div className="col-lg-4 col-md-6 p-0">
            <div className="why-choose-img">
              <img
                style={{ width: '634.328px', height: '423px' }}
                src={whyChoosUs[0].imageUrl}
                alt="Why Us"
              />
            </div>
          </div>

          <div className="col-lg-4 col-md-6 p-0">
            <div className="why-choose-content">
              <h3>
                <IntlMessages id="home.whyus.natureplayground" />
              </h3>
              <p>
                <IntlMessages id="home.whyus.immerseyourselfinthebeauty" />
              </p>
              <span>
                <IntlMessages id="home.whyus.01" />
              </span>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 p-0">
            <div className="why-choose-img">
              <img
                style={{ width: '634.328px', height: '423px' }}
                src={whyChoosUs[1].imageUrl}
                alt="Why Us"
              />
            </div>
          </div>

          <div className="col-lg-4 col-md-6 p-0">
            <div className="why-choose-content">
              <h3>
                <IntlMessages id="home.whyus.adrenalineFueledExcursions" />
              </h3>
              <p>
                <IntlMessages id="home.whyus.braceyourselfforheartpounding" />
              </p>

              <span>
                <IntlMessages id="home.whyus.02" />
              </span>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 p-0">
            <div className="why-choose-img">
              <img
                style={{ width: '634.328px', height: '423px' }}
                src={whyChoosUs[2].imageUrl}
                alt="Why Us"
              />
            </div>
          </div>

          <div className="col-lg-4 col-md-6 p-0">
            <div className="why-choose-content">
              <h3>
                <IntlMessages id="home.whyus.epicTreksandTrails" />
              </h3>
              <p>
                <IntlMessages id="home.whyus.laceupyourbootsandembark" />
              </p>
              <span>
                <IntlMessages id="home.whyus.03" />
              </span>
            </div>
          </div>
        </div>
      </section>

      <Cta />
    </>
  )
}

export default WhyUs
