import React, { ReactNode } from 'react'

type AuthRoutesProps = {
  children: ReactNode
}

const AuthRoutes: React.FC<AuthRoutesProps> = ({ children }) => {
  return <>{children}</>
}

export default AuthRoutes
