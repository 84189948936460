import IntlMessages from '@/helpers/IntlMessages'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import lax from 'lax.js'
interface LaxButtonProps {
  buttonText?: string
}

const LaxButton = ({ buttonText }: LaxButtonProps) => {
  useEffect(() => {
    lax.setup()
    const handleScroll = () => {
      lax.update(window.scrollY)
    }

    document.addEventListener('scroll', handleScroll, true)

    lax.update(window.scrollY)

    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Link
      to="/about-us"
      className="btn btn-primary lax"
      data-lax-preset="driftLeft"
    >
      <IntlMessages id={`${buttonText}`} />
    </Link>
  )
}

export default LaxButton
