import React from 'react'
const AboutUs = React.lazy(
  () => import('~/modules/consumerPortal/pages/AboutOne')
)
const Contact = React.lazy(
  () => import('~/modules/consumerPortal/pages/Contact')
)
const Faq = React.lazy(() => import('~/modules/consumerPortal/pages/Faq'))
const EventDetails = React.lazy(
  () => import('~/modules/consumerPortal/pages/EventDetails')
)
const Events = React.lazy(() => import('~/modules/consumerPortal/pages/Events'))
const PrivatePolicy = React.lazy(
  () => import('~/modules/consumerPortal/pages/Poc')
)
const TermsServices = React.lazy(
  () => import('~/modules/consumerPortal/pages/TermsServices')
)

export const consumerPagesConfigs = [
  {
    path: '/about-us',
    element: <AboutUs />,
  },
  {
    path: '/contact',
    element: <Contact />,
  },
  {
    path: '/faq',
    element: <Faq />,
  },
  {
    path: '/all-events',
    element: <Events />,
  },
  {
    path: '/eventdetails/:eventId',
    element: <EventDetails />,
  },
  {
    path: '/private-policy',
    element: <PrivatePolicy />,
  },
  {
    path: '/terms-services',
    element: <TermsServices />,
  },
]
