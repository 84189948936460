import { authRole } from '@/constants/AppConst'
import jwt_decode, { JwtPayload } from 'jwt-decode'

export const getUserFromFirebase = (user: any) => {
  if (user) {
    const roles: string[] = [authRole.User]
    if (user?.accessToken) {
      const decoded = jwt_decode<JwtPayload>(user?.accessToken)
      if ('admin' in decoded && !!decoded.admin) {
        roles.push(authRole.Admin)
      }
      if ('coorganizer' in decoded && !!decoded.coorganizer) {
        roles.push(authRole.CoOrganizer)
      }
      if ('organizer' in decoded && !!decoded.organizer) {
        roles.push(authRole.Organizer)
      }
      if ('moderator' in decoded && !!decoded.moderator) {
        roles.push(authRole.Moderator)
      }
    }
    return {
      id: 1,
      uid: user.uid,
      displayName: user.displayName,
      email: user.email,
      photoURL: user.photoURL ? user.photoURL : '',
      role: roles,
    }
  }
  return user
}
