export const apiHelperFunctions = () => {
  // --------------------------- GET Helper Function Start ---------------------------

  async function api<T>(url: string, requestOptions?: RequestInit): Promise<T> {
    const response = await fetch(url, requestOptions)
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    return response.json() as Promise<T>
  }
  // --------------------------- GET Helper Function End ---------------------------

  // --------------------------- Helper Function Start ---------------------------

  async function apiUnwrap<T>(
    url: string,
    requestOptions?: RequestInit
  ): Promise<T> {
    const response = await fetch(url, requestOptions)

    if (!response.ok) {
      throw new Error(response.statusText)
    }
    try {
      const data = await response.text() // Parse the response content as JSON
      return data as T
    } catch (error) {
      throw new Error(error as string)
    }
  }

  function apiNoData<T>(url: string, requestOptions?: RequestInit): Promise<T> {
    return fetch(url, requestOptions).then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      return response.text() as Promise<T>
    })
  }

  // --------------------------- Helper Function End ---------------------------

  return { api, apiUnwrap, apiNoData }
}
