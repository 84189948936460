import React, { useEffect, useState } from 'react'
import { useAuthUser } from '@/hooks/AuthHooks'
import { Formik } from 'formik'
import * as yup from 'yup'
import PersonalInfoForm from './PersonalInfoForm'
import { Box } from '@mui/material'
import { useUserApi } from '@/hooks/KrazzyApi/useUserApi'
import AppInfoView from '@/components/lib/AppInfoView'
import ChangePassword from '../ChangePassword'
import { User } from '~/modules/adminPages/@types/getuser'

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Invalid email format')
    .required('Required')
    .matches(new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$')),
  phone: yup.number(),
})
const PersonalInfo = () => {
  const [userData, setUserData] = useState<User>()
  const { user } = useAuthUser()
  const { updateUserInfo, getUserById } = useUserApi()

  const dataUser = async () => {
    const data = await getUserById()
    setUserData(data as User)
  }

  useEffect(() => {
    dataUser()
  }, [])

  const updateUser1 = async (data: any) => {
    if (data.photoURL == '/assets/images/placeholder.jpg') {
      alert('Upload Profile picture')
      data.photoURL = ''
      updateUserInfo(data)
    } else {
      updateUserInfo(data)
    }
  }

  return (
    <>
      <Box>
        <Box
          sx={{
            position: 'relative',
            maxWidth: 550,
            marginBottom: '20px',
          }}
        >
          <Formik
            validateOnBlur={true}
            initialValues={{
              ...user,
              photoURL: user.photoURL ? user.photoURL : userData?.photoURL,
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting }) => {
              setSubmitting(true)
              //TODO Api Call here to save user info
              updateUser1(data)
              setSubmitting(false)
            }}
          >
            {({ values, setFieldValue }) => {
              return (
                <PersonalInfoForm
                  values={values}
                  setFieldValue={setFieldValue}
                />
              )
            }}
          </Formik>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'relative',
          maxWidth: 550,
          marginTop: '20px',
        }}
      >
        <ChangePassword />
      </Box>
      <AppInfoView />
    </>
  )
}

export default PersonalInfo
