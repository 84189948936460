import React, { ReactNode } from 'react'
import ThemeContextProvider from './ThemeContextProvider'
import LocaleContextProvider from './LocaleContextProvider'
import LayoutContextProvider from './LayoutContextProvider'
import SidebarContextProvider from './SidebarContextProvider'

interface AppContextProviderProps {
  children: ReactNode
}

const AppContextProvider: React.FC<AppContextProviderProps> = ({
  children,
}) => {
  return (
    <ThemeContextProvider>
      <LocaleContextProvider>
        <LayoutContextProvider>
          <SidebarContextProvider>{children}</SidebarContextProvider>
        </LayoutContextProvider>
      </LocaleContextProvider>
    </ThemeContextProvider>
  )
}

export default AppContextProvider
