import React from 'react'
import { useThemeContext } from '@/context/AppContextProvider/ThemeContextProvider'
import { alpha, Box } from '@mui/material'
import { Link } from 'react-router-dom'
import { ReactComponent as Logo } from '~/assets/icons/logo.svg'
import { ReactComponent as LogoText } from '~/assets/icons/logo_text.svg'

type AppLogoProps = {
  color?: string
}

const AppLogo: React.FC<AppLogoProps> = () => {
  const { theme } = useThemeContext()

  return (
    <Box
      sx={{
        height: { xs: 56, sm: 80 },
        padding: 2.5,
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
          height: { xs: 40, sm: 65 },
        },
      }}
      className="app-logo"
    >
      <Link to="/">
        <Logo fill={theme.palette.primary.main} />
      </Link>
      <Box
        sx={{
          mt: 3,
          display: { xs: 'none', md: 'block' },
          '& svg': {
            height: { xs: 25, sm: 30 },
          },
        }}
      >
        <LogoText fill={alpha(theme.palette.text.primary, 0.8)} />
      </Box>
    </Box>
  )
}

export default AppLogo
