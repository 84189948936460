import { useState } from 'react'
import OwlCarousel from 'react-owl-carousel3'
import VisibilitySensor from 'react-visibility-sensor'
import { Link } from 'react-router-dom'
import ModalVideo from 'react-modal-video'
import IntlMessages from '@/helpers/IntlMessages'

const options = {
  loop: true,
  nav: true,
  dots: false,
  autoplayHoverPause: true,
  autoplay: true,
  items: 1,
  navText: [
    "<i class='icofont-swoosh-left'></i>",
    "<i class='icofont-swoosh-right'></i>",
  ],
}

function MainBanner({ mainBanner }: any) {
  const [isOpen, setOpen] = useState(false)

  return (
    <>
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId="cRXm1p-CNyk"
        onClose={() => setOpen(false)}
      />

      <OwlCarousel className="home-slides owl-carousel owl-theme" {...options}>
        {mainBanner.map((e: { imageUrl: string }, idx: any) => (
          <div
            key={`mainbanner${idx}`}
            className="main-banner item-bg3"
            style={{
              backgroundImage: `url(${e.imageUrl})`,
            }}
          >
            <div className="d-table">
              <div className="d-table-cell">
                <div className="container">
                  <VisibilitySensor>
                    {({ isVisible }: { isVisible: boolean }) => (
                      <div className="main-banner-content">
                        <p className={isVisible ? 'animated fadeInDown' : ''}>
                          <IntlMessages id="main.banner.areyou" />
                          <span>
                            <IntlMessages id="main.banner.ready" />
                          </span>
                          <IntlMessages id="main.banner.forfun" />
                        </p>
                        <h1 className={isVisible ? 'animated fadeInUp' : ''}>
                          <IntlMessages id="main.banner.adventurebeginshere" />
                          <b className=".span">
                            <IntlMessages id="main.banner.begins" />
                          </b>
                          <span>
                            <IntlMessages id="main.banner.krazzy" />
                          </span>
                          <br /> <IntlMessages id="main.banner.adventures" />
                        </h1>
                        <div className="button-box">
                          <Link
                            to="/all-events"
                            className={`btn btn-primary ${
                              isVisible ? 'animated fadeInDown' : ''
                            }`}
                          >
                            <IntlMessages id="main.banner.bookseat" />
                          </Link>
                        </div>
                      </div>
                    )}
                  </VisibilitySensor>
                </div>
              </div>
            </div>
          </div>
        ))}
      </OwlCarousel>
    </>
  )
}

export default MainBanner
