import React, { ReactNode } from 'react'
import { useInfoViewActionsContext } from '@/context/AppContextProvider/InfoViewContextProvider'
import FirebaseAuthProvider from '@/services/auth/firebase/FirebaseAuthProvider'

type Props = {
  children: ReactNode
}

const AppAuthProvider = ({ children }: Props) => {
  const { fetchStart, fetchSuccess, fetchError } = useInfoViewActionsContext()

  return (
    <FirebaseAuthProvider
      fetchStart={fetchStart}
      fetchError={fetchError}
      fetchSuccess={fetchSuccess}
    >
      {children}
    </FirebaseAuthProvider>
  )
}

export default AppAuthProvider
