import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel3'
import IntlMessages from '@/helpers/IntlMessages'
import { ConsumerEventType } from '~/modules/adminPages/@types/app'
import { useAppDataContext } from '@/context/AppDataContext/AppDataContextProvider'
import ShowEvents from '../common/ShowEvents'

const options = {
  loop: true,
  nav: true,
  dots: false,
  margin: 30,
  autoplayHoverPause: true,
  autoplay: true,
  navText: [
    "<i class='icofont-rounded-left'></i>",
    "<i class='icofont-rounded-right'></i>",
  ],
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    1200: {
      items: 3,
    },
  },
}

export default function Events() {
  const { welcomePage } = useAppDataContext()
  const event = welcomePage?.publishEventResponse

  return (
    <>
      <section className="blog-area ptb-120 bg-image">
        <div className="container">
          <div className="section-title">
            <span>
              <IntlMessages id="home.latestnews.infoupdate" />
            </span>
            <h2>
              {/* Decide heading */}
              <IntlMessages id="home.latestnews.our" />

              <b>
                <IntlMessages id="home.latestnews.events" />
              </b>
            </h2>

            <div className="bg-title lax" data-lax-preset="driftLeft">
              <IntlMessages id="home.latestnews.highlights" />
            </div>

            <Link to="/all-events" className="btn btn-primary">
              <IntlMessages id="home.latestnews.viewallevents" />
            </Link>

            <div className="bar"></div>
          </div>

          {event!.length < 4 ? (
            <div className='homepageEvents' >
              {event?.map((event: ConsumerEventType) => (
                <ShowEvents event={event} />
              ))}
            </div>
          ) : (
            <div className="row">
              <OwlCarousel
                className="blog-slides owl-carousel owl-theme"
                {...options}
              >
                {event?.map((event: ConsumerEventType) => (
                  <ShowEvents event={event} />
                ))}
              </OwlCarousel>
            </div>
          )}
        </div>
      </section>
    </>
  )
}
