import {
  useFirebase,
  useFirebaseActions,
} from '@/services/auth/firebase/FirebaseAuthProvider'
import { getUserFromFirebase } from '@/helpers'

export const useAuthUser = () => {
  const { user, isAuthenticated, isLoading } = useFirebase()
  return {
    isLoading: isLoading,
    isAuthenticated: isAuthenticated,
    user: getUserFromFirebase(user),
    tokenUser: user,
  }
}

export const useAuthMethod = () => {
  const {
    logInWithEmailAndPassword,
    registerUserWithEmailAndPassword,
    logInWithPopup,
    logout,
  } = useFirebaseActions()

  return {
    logInWithEmailAndPassword,
    registerUserWithEmailAndPassword,
    logInWithPopup,
    logout,
  }
}
