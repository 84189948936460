import React from 'react'
const Signin = React.lazy(() => import('../../library/modules/lib/userPages/StyledUserPages/Signin'))
const Signup = React.lazy(() => import('../../library/modules/lib/userPages/StyledUserPages/Signup'))
const ForgotPassword = React.lazy(
  () => import('~/modules/auth/signin/ForgotPassword')
)

export const authRouteConfig = [
  {
    path: '/signin',
    element: <Signin />,
  },
  {
    path: '/signup',
    element: <Signup />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
  },
]
