/* eslint-disable @typescript-eslint/no-unused-vars */
import { useInfoViewActionsContext } from '@/context/AppContextProvider/InfoViewContextProvider'
import { useAuthUser } from '../AuthHooks'
import {
  User,
  UserPassword,
  UserType,
} from '~/modules/adminPages/@types/getuser'
import { FirebaseError } from 'firebase/app'
import { apiHelperFunctions } from './apiHelperFunctions'

export const useUserApi = () => {
  const { fetchStart, fetchSuccess, fetchError, showMessage } =
    useInfoViewActionsContext()
  const { api, apiUnwrap, apiNoData } = apiHelperFunctions()
  const { tokenUser } = useAuthUser()
  const token = tokenUser?.accessToken

  // --------------------------- GET ALLUSERS START ---------------------------
  async function userName(uid: any, name: string): Promise<void> {
    try {
      fetchStart()
      const requestOptions: RequestInit = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
      fetchSuccess()
      await apiNoData<void>(
        `${process.env.REACT_APP_API_URL}/user/${uid}/${name}`,
        requestOptions
      )
    } catch (error) {
      if (error instanceof FirebaseError) {
        fetchError(error.code)
        throw error
      } else {
        fetchError('An error occurred')
        throw error
      }
    }
  }
  // --------------------------- GET ALLUSERS END ---------------------------

  // --------------------------- GET ALLUSERS START ---------------------------
  async function getUsers(): Promise<UserType[]> {
    try {
      fetchStart()
      const requestOptions: RequestInit = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }

      const data = await api<UserType[]>(
        `${process.env.REACT_APP_API_URL}/getusers`,
        requestOptions
      )
      fetchSuccess()
      return data
    } catch (error) {
      if (error instanceof FirebaseError) {
        fetchError(error.code)
        throw error
      } else {
        fetchError('An error occurred')
        throw error
      }
    }
  }
  // --------------------------- GET ALLUSERS END ---------------------------

  // --------------------------- GET ALLUSERS START ---------------------------
  async function getUserById(): Promise<UserType> {
    try {
      fetchStart()
      const requestOptions: RequestInit = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }

      const data = await api<UserType>(
        `${process.env.REACT_APP_API_URL}/getuser-id`,
        requestOptions
      )
      fetchSuccess()
      return data
    } catch (error) {
      if (error instanceof FirebaseError) {
        fetchError(error.code)
        throw error
      } else {
        fetchError('An error occurred')
        throw error
      }
    }
  }
  // --------------------------- GET ALLUSERS END ---------------------------

  // --------------------------- UPDATE USER Start ---------------------------

  async function updateUser(d: UserType): Promise<UserType> {
    try {
      fetchStart()
      const requestOptions: RequestInit = {
        method: 'PUT',
        body: JSON.stringify({
          uid: d.id,
          name: d.name,
          email: d.email,
          address: d.address,
          phone: d.phone,
          status: d.status,
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
      fetchSuccess()
      showMessage('Success')
      const data = await apiUnwrap<UserType>(
        `${process.env.REACT_APP_API_URL}/updateuser`,
        requestOptions
      )
      return data
    } catch (error) {
      if (error instanceof FirebaseError) {
        fetchError(error.code)
        throw error
      } else {
        fetchError('An error occurred')
        throw error
      }
    }
  }

  // --------------------------- UPDATE USER End ---------------------------

  // --------------------------- Flag USER Start ---------------------------

  async function flagUser(id: string, flag: boolean): Promise<UserType> {
    try {
      fetchStart()
      const requestOptions: RequestInit = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
      fetchSuccess()
      showMessage('Success')
      const data = await apiUnwrap<UserType>(
        `${process.env.REACT_APP_API_URL}/flag/${id}/${flag}`,
        requestOptions
      )
      return data
    } catch (error) {
      if (error instanceof FirebaseError) {
        fetchError(error.code)
        throw error
      } else {
        fetchError('An error occurred')
        throw error
      }
    }
  }

  // --------------------------- Flag USER End ---------------------------

  // --------------------------- UPDATE USER NAME PROFILE Start ---------------------------

  async function updateUserInfo(d: User): Promise<User> {
    try {
      fetchStart()
      const requestOptions: RequestInit = {
        method: 'PUT',
        body: JSON.stringify({
          displayName: d.displayName,
          photoURL: d.photoURL,
          address: d.address,
          phone: d.phone,
          type: d.type,
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
      fetchSuccess()
      showMessage('Success')
      const data = await apiUnwrap<User>(
        `${process.env.REACT_APP_API_URL}/update-userinfo`,
        requestOptions
      )

      return data
    } catch (error) {
      if (error instanceof FirebaseError) {
        fetchError(error.code)
        throw error
      } else {
        fetchError('An error occurred')
        throw error
      }
    }
  }

  async function updateUserPassword(d: UserPassword): Promise<UserPassword> {
    try {
      fetchStart()
      const requestOptions: RequestInit = {
        method: 'PUT',
        body: JSON.stringify({
          newPassword: d.newPassword,
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
      fetchSuccess()
      showMessage('Success')
      const data = await apiUnwrap<UserPassword>(
        `${process.env.REACT_APP_API_URL}/update-password`,
        requestOptions
      )

      return data
    } catch (error) {
      if (error instanceof FirebaseError) {
        fetchError(error.code)
        throw error
      } else {
        fetchError('An error occurred')
        throw error
      }
    }
  }

  // --------------------------- UPDATE USER NAME PROFILE End ---------------------------

  // --------------------------- DELETE USER Start ---------------------------
  async function deleteUser(id: any): Promise<void> {
    try {
      fetchStart()
      const requestOptions: RequestInit = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
      fetchSuccess()
      showMessage('Success')
      await apiNoData<void>(
        `${process.env.REACT_APP_API_URL}/deleteuser/${id}`,
        requestOptions
      )
      fetchSuccess('User Deleted')
    } catch (error) {
      if (error instanceof FirebaseError) {
        fetchError(error.code)
        throw error
      } else {
        fetchError('An error occurred')
        throw error
      }
    }
  }
  // --------------------------- DELETE USER End ---------------------------

  // --------------------------- Change Authorize Start ---------------------------
  async function changeAuthorize(data: any): Promise<void> {
    try {
      fetchStart()
      const uuid = data.id
      const auth = data.auth

      const requestOptions: RequestInit = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
      fetchSuccess()
      showMessage(`Now ${data.name} is ${auth}`)
      await apiNoData<void>(
        `${process.env.REACT_APP_API_URL}/changeauth/${uuid}/${auth}`,
        requestOptions
      )
    } catch (error) {
      if (error instanceof FirebaseError) {
        fetchError(error.code)
        throw error
      } else {
        fetchError('An error occurred')
        throw error
      }
    }
  }
  // --------------------------- DELETE EVENTS End ---------------------------

  return {
    getUsers,
    updateUser,
    deleteUser,
    changeAuthorize,
    updateUserInfo,
    updateUserPassword,
    userName,
    getUserById,
    flagUser,
  }
}
