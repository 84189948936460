import React, { ReactNode } from 'react'
import Box from '@mui/material/Box'
import { SxProps } from '@mui/system'

type AdminContentViewProps = {
  sxStyle?: SxProps
  children: ReactNode
}

const AdminContentView: React.FC<AdminContentViewProps> = ({
  children,
  sxStyle,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
        maxWidth: { xl: 1650 },
        mx: { xl: 'auto' },
        width: { xl: '100%' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          p: { xs: 5, md: 7.5, xl: 12.5 },
          ...sxStyle,
        }}
        className="app-content"
      >
        {children}
      </Box>
    </Box>
  )
}

export default AdminContentView
