import { authRole } from '@/constants/AppConst'

export const checkPermission = (routeRole: any, userRole: any) => {
  if (!routeRole || !routeRole) {
    return true
  }

  if (userRole && Array.isArray(userRole) && !Array.isArray(routeRole)) {
    return userRole.indexOf(routeRole) >= 0
  }
  if (routeRole.length === 0) {
    return !userRole || userRole.length === 0
  }
  if (userRole && Array.isArray(userRole) && Array.isArray(routeRole)) {
    return routeRole.some((r) => userRole.indexOf(r) >= 0)
  }
  return routeRole.indexOf(userRole) >= 0
}

export const isAdmin = (userRole: string[]): boolean => {
  return checkPermission(
    [authRole.Admin, authRole.Organizer, authRole.CoOrganizer],
    userRole
  )
}
