import { useAuthUser } from '@/hooks'
import { useBookEvent } from '@/hooks/KrazzyApi/useBookEvent'
import { useEffect, useState } from 'react'
import { ConsumerEventType } from '~/modules/adminPages/@types/app'
import { Link, useNavigate } from 'react-router-dom'
import IntlMessages from '@/helpers/IntlMessages'
import AppLoader from '@/components/lib/AppLoader'

const BookedEvents = () => {
  const { getEventByUserId } = useBookEvent()

  const [event, setEvent] = useState<ConsumerEventType[] | undefined>([])
  const { user } = useAuthUser()
  const [eventBool, setEventBool] = useState(false)

  const events = async () => {
    const data = await getEventByUserId(user.uid)
    setEvent(data)
    setEventBool(true)
  }

  useEffect(() => {
    events()
  }, [])

  const navigate = useNavigate()
  const navigateToCreateEvent = (eventId: any) => {
    navigate(`/eventdetails/${eventId}`)
  }

  return (
    <div style={{ height: '70vh', overflowY: 'scroll' }}>
      {!eventBool ? (
        <AppLoader />
      ) : (
        event?.map((e: ConsumerEventType, idx: any) => (
          <div key={`event-${idx}`} className="col-lg-12 col-md-12">
            <div className="single-blog-post">
              <div className="blog-image">
                <Link to="#">
                  <img
                    src={e.uploadImageUrl}
                    alt="blog"
                    style={{ width: '400px', height: '250px' }}
                  />
                </Link>

                <div className="post-tag">
                  <Link to="#">{e.eventType.toUpperCase()}</Link>
                </div>
                <div className="post-tag" style={{ marginTop: '70px' }}>
                  <Link to="#">{e.isPresent ? 'ATTEND' : 'NOT ATTEND'}</Link>
                </div>
              </div>

              <div className="blog-post-content">
                <span className="date">
                  {e.eventDate.toString().split('T')[0]}
                </span>
                <h3>
                  <Link to="#">{e.eventName}</Link>
                </h3>
                <p>
                  {e.eventDescription.length > 25
                    ? e.eventDescription.substring(0, 25) + '...'
                    : e.eventDescription}
                </p>

                <button
                  style={{ border: 'none', background: 'none' }}
                  onClick={() => navigateToCreateEvent(e.id)}
                  className="read-more-btn"
                >
                  <IntlMessages id="readmore" />
                  <i className="icofont-double-right"></i>
                </button>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  )
}
export default BookedEvents
