import React, { useEffect, useRef } from 'react'
import LoadingBar from 'react-top-loading-bar'

const LazyLoad = () => {
  const ref = useRef(null)

  useEffect(() => {
    (ref.current as any)?.continuousStart()

    return () => {
      (ref.current as any)?.complete()
    }
  }, [])

  return <LoadingBar color="#f11946" ref={ref} shadow={true} />
}

export default LazyLoad
