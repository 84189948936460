import { Link } from 'react-router-dom'
import LaxButton from '~/modules/consumerPortal/shared/LaxButton'
import IntlMessages from '@/helpers/IntlMessages'

const About = ({ mainAbout }: any) => {
  return (
    <section className="about-area ptb-120 bg-image">
      <div className="container">
        <div className="row h-100 align-items-center">
          <div className="col-lg-6">
            <div className="about-content">
              <span>
                <IntlMessages id="home.about.jointheevent" />
              </span>
              <h2>
                <IntlMessages id="home.about.welcometo" />
                <b>
                  <IntlMessages id="home.about.krazzyAdventures" />
                </b>
                <IntlMessages id="home.about.yourUltimateEscapeAwaits!" />
              </h2>
              <p>
                <IntlMessages id="home.about.embarkonajourneywithus," />
              </p>
              <Link to="/all-events" className={`btn btn-secondary`}>
                <IntlMessages id="common.bookseat" />
              </Link>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-image">
              <img
                src={mainAbout.length > 0 ? mainAbout[0].imageUrl : ''}
                className="about-img1"
                alt="about"
                style={{ height: '416px', width: '624px' }}
              />
              <img
                src={mainAbout.length > 1 ? mainAbout[1].imageUrl : ''}
                className="about-img2"
                alt="about"
                style={{ height: '424px', width: '309px' }}
              />
              <LaxButton buttonText="laxbutton.exploremoreabout" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About
