import MainBanner from '~/modules/consumerPortal/home/MainBanner'
import About from '~/modules/consumerPortal/common/AboutUs'
import WhyUs from '~/modules/consumerPortal/home/WhyUs'
import Footer from '~/modules/consumerPortal/common/Footer'
import GoTop from '~/modules/consumerPortal/shared/GoTop'
import Navigation from '~/modules/consumerPortal/navigation/Navigation'
import { useAppDataContext } from '@/context/AppDataContext/AppDataContextProvider'
import Events from '../home/Events'
import { useEffect } from 'react'

function Home() {
  const { welcomePage } = useAppDataContext()

  const bannerImages = welcomePage?.mainBanner ?? []
  const aboutImages = welcomePage?.joinTheEvents ?? []
  const homeWhyChoosUs = welcomePage?.recentEvents ?? []

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      {/* navigation */}
      <Navigation />
      {/* Main Banner  */}
      <MainBanner mainBanner={bannerImages} />
      {/* About Area */}
      <About mainAbout={aboutImages} />
      {/* Events Area */}
      <Events />
      {/* Why Choose Us Area */}
      <WhyUs whyChoosUs={homeWhyChoosUs} />
      {/* Footer Area */}
      <Footer />
      {/* Back to top */}
      <GoTop />
    </>
  )
}

export default Home
