import React from 'react'
import { RoutePermittedRole } from '@/constants/AppEnums'
const BookEvents = React.lazy(
  () => import('../../modules/adminPages/admincontrols/event/bookEvent')
)
const GetUser = React.lazy(
  () => import('../../modules/adminPages/admincontrols/User/getUsers')
)
const CreateEvent = React.lazy(
  () => import('../../modules/adminPages/admincontrols/event/createEvent')
)
const GetEvent = React.lazy(
  () => import('../../modules/adminPages/admincontrols/event/getEvent')
)
const GetPublishEvent = React.lazy(
  () => import('../../modules/adminPages/admincontrols/event/publishEvents')
)
const Upload = React.lazy(
  () => import('../../modules/adminPages/admincontrols/image/fileUpload')
)
const GetAllImages = React.lazy(
  () => import('../../modules/adminPages/admincontrols/image/getAllImage')
)
const UploadEventPictures = React.lazy(
  () => import('../../modules/adminPages/admincontrols/event/eventPicture')
)

export const adminPagesConfigs = [
  {
    path: '/admin/get-user',
    element: <GetUser />,
    permittedRole: [
      RoutePermittedRole.Admin,
      RoutePermittedRole.Organizer,
      RoutePermittedRole.CoOrganizer,
    ],
  },
  {
    path: '/admin/create-event',
    element: <CreateEvent />,
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.Organizer],
  },
  {
    path: '/admin/update-event/:eventId',
    element: <CreateEvent />,
    permittedRole: [
      RoutePermittedRole.Admin,
      RoutePermittedRole.Organizer,
      RoutePermittedRole.CoOrganizer,
    ],
  },
  {
    path: '/admin/get-event',
    element: <GetEvent />,
    permittedRole: [
      RoutePermittedRole.Admin,
      RoutePermittedRole.Organizer,
      RoutePermittedRole.CoOrganizer,
    ],
  },
  {
    path: '/admin/get-publish-events',
    element: <GetPublishEvent />,
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.Organizer],
  },
  {
    path: '/admin/get-book-event',
    element: <BookEvents />,
    permittedRole: [
      RoutePermittedRole.Admin,
      RoutePermittedRole.Organizer,
      RoutePermittedRole.CoOrganizer,
    ],
  },
  {
    path: '/admin/upload',
    element: <Upload />,
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.Organizer],
  },
  {
    path: '/admin/get-all-images',
    element: <GetAllImages />,
    permittedRole: [
      RoutePermittedRole.Admin,
      RoutePermittedRole.Organizer,
      RoutePermittedRole.CoOrganizer,
    ],
  },
  {
    path: '/admin/upload-images/:eventId',
    element: <UploadEventPictures />,
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.Organizer],
  },
]
