import { ReactComponent as Logo } from '~/assets/icons/logo.svg'
import { Box } from '@mui/material'
import { Link } from 'react-router-dom'

const AppLogoWhite = () => {
  return (
    <Box
      sx={{
        height: { xs: 56, sm: 80 },
        padding: 2.5,
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
          height: { xs: 40, sm: 65 },
        },
      }}
      className="app-logo"
    >
      <Link to="/">
        <Logo
          style={{
            backgroundColor: '#ffffff',
            borderRadius: '6px',
            opacity: '0.8',
          }}
        />
      </Link>
    </Box>
  )
}

export default AppLogoWhite
