import IntlMessages from '@/helpers/IntlMessages'
import React from 'react'
import { Link } from 'react-router-dom'

function Cta() {
  return (
    <section className="cta-area">
      <div className="container">
        <div className="row h-100 align-items-center">
          <div className="col-lg-8">
            <h3>
              <IntlMessages id="home.cta.explore,experience,evolve" />
            </h3>
            <span>
              <IntlMessages id="home.cta.yourUltimateAdventurePlayground." />
            </span>
          </div>

          <div className="col-lg-4 text-right">
            <Link to="/all-events" className={`btn btn-secondary`}>
              <IntlMessages id="main.banner.bookseat" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Cta
