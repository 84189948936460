import React from 'react'
import { Navigate } from 'react-router-dom'
import { authRouteConfig } from './AuthRoutes'
import Error403 from '../../modules/errorPages/error403'
import { errorPagesConfigs } from './ErrorPagesRoutes'
import { accountPagesConfigs } from './AccountRoutes'
import { adminPagesConfigs } from './AdminPages'
import { initialUrl } from '@/constants/AppConst'
import { consumerPagesConfigs } from './ConsumerRoutes'
import Home from '~/modules/consumerPortal/pages/Home'

const authorizedStructure = {
  fallbackPath: '/',
  unAuthorizedComponent: <Error403 />,
  routes: [...accountPagesConfigs, ...adminPagesConfigs],
}

const unAuthorizedStructure = {
  fallbackPath: initialUrl,
  routes: [...authRouteConfig],
}
const anonymousStructure = {
  routes: errorPagesConfigs.concat([
    {
      path: '/',
      element: <Home />,
    },
    {
      path: '*',
      element: <Navigate to="/error-404" />,
    },
    ...consumerPagesConfigs,
  ]),
}

export { authorizedStructure, unAuthorizedStructure, anonymousStructure }
