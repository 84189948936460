import { ApplicationData } from '~/modules/adminPages/@types/app'

export const AppDataActions = {
  LOAD_APPLICATION_DATA_SUCCESS: 'LOAD_APPLICATION_DATA_SUCCESS',
  LOAD_APPLICATION_DATA_FAIL: 'LOAD_APPLICATION_DATA_FAIL',
} as const

export function contextReducer(state: ApplicationData, action: any) {
  switch (action.type) {
    case AppDataActions.LOAD_APPLICATION_DATA_SUCCESS: {
      return {
        ...state,
        isApplicationDataLoaded: true,
        welcomePage: action.payload.welcomePage,
      }
    }
    case AppDataActions.LOAD_APPLICATION_DATA_FAIL: {
      return {
        ...state,
        isApplicationDataLoaded: false,
        isError: true,
        welcomePage: undefined,
      }
    }
    default:
      return state
  }
}
