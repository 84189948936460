import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined'
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined'
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined'
import PublishIcon from '@mui/icons-material/Publish'
import UploadIcon from '@mui/icons-material/Upload'
import ImageIcon from '@mui/icons-material/Image'
import { RoutePermittedRole } from '@/constants/AppEnums'
const routesConfig = [
  {
    id: 'user',
    messageId: 'routeconfig.user',
    title: 'user',
    type: 'group',
    children: [
      {
        id: 'get-user',
        title: 'get user',
        messageId: 'routeconfig.getuser',
        type: 'item',
        icon: <PermIdentityOutlinedIcon />,
        url: '/admin/get-user',
        permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.Organizer],
      },
    ],
  },
  {
    id: 'event',
    title: 'event',
    messageId: 'routeconfig.events',
    type: 'group',
    children: [
      {
        id: 'create-event',
        title: 'create event',
        messageId: 'routeconfig.createevent',
        type: 'item',
        icon: <EventAvailableOutlinedIcon />,
        url: '/admin/create-event',
        permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.Organizer],
      },
      {
        id: 'get-event',
        title: 'get event',
        messageId: 'routeconfig.getevent',
        type: 'item',
        icon: <EventNoteOutlinedIcon />,
        url: '/admin/get-event',
        permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.Organizer],
      },
      {
        id: 'get-publish-event',
        title: 'get publish event',
        messageId: 'get-publish-event',
        type: 'item',
        icon: <PublishIcon />,
        url: '/admin/get-publish-events',
        permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.Organizer],
      },
      {
        id: 'get-book-event',
        title: 'get book event',
        messageId: 'book-event',
        type: 'item',
        icon: <PublishIcon />,
        url: '/admin/get-book-event',
        permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.Organizer],
      },
    ],
  },
  {
    id: 'image',
    title: 'image',
    messageId: 'routeconfig.images',
    type: 'group',
    children: [
      {
        id: 'upload-image',
        title: 'upload-image',
        messageId: 'routeconfig.uploadimage',
        type: 'item',
        icon: <UploadIcon />,
        url: '/admin/upload',
        permittedRole: [RoutePermittedRole.Admin],
      },
      {
        id: 'get-all-images',
        title: 'get all images',
        messageId: 'routeconfig.getallimages',
        type: 'item',
        icon: <ImageIcon />,
        url: '/admin/get-all-images',
        permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.Organizer],
      },
    ],
  },
]
export default routesConfig
