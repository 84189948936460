import IntlMessages from '@/helpers/IntlMessages'
import { useAuthUser } from '@/hooks'
import { useBookEvent } from '@/hooks/KrazzyApi/useBookEvent'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ConsumerEventType } from '~/modules/adminPages/@types/app'

const ShowEvents = ({ event }: { event: ConsumerEventType }) => {
  const { isBooked } = useBookEvent()
  const { tokenUser, isAuthenticated } = useAuthUser()
  const navigate = useNavigate()
  const [isBook, setIsBooked] = useState(false)

  const navigateToCreateEvent = (eventId: any) => {
    navigate(`/eventdetails/${eventId}`)
  }

  const bookEventCheck = async () => {
    const d = await isBooked(tokenUser!.uid, event.id)
    setIsBooked(d)
  }

  useEffect(() => {
    isAuthenticated && bookEventCheck()
  }, [isAuthenticated])

  return (
    <>
      <div
        className="single-blog-post"
        style={{ width: '400px', marginRight: '20px' }}
      >
        <div key={event.id} className="blog-image">
          <Link to="#">
            <img
              src={event.uploadImageUrl}
              alt="blog"
              style={{ width: '400px', height: '300px' }}
            />
          </Link>

          <div className="post-tag">
            <Link to="#">{event.eventType.toUpperCase()}</Link>
            {isAuthenticated ? (
              <Link to="#" style={{ marginTop: '10px' }}>
                {isBook ? 'Registered' : 'Register Now'}
              </Link>
            ) : null}
          </div>
        </div>

        <div className="blog-post-content">
          <span className="date">
            {event.eventDate.toString().split('T')[0]}
          </span>
          <h3>
            <button
              style={{ border: 'none', background: 'none' }}
              onClick={() => navigateToCreateEvent(event.id)}
              className="read-more-btn"
            >
              {event.eventName}
            </button>
          </h3>
          <p>
            {event.eventDescription.length > 25
              ? event.eventDescription.substring(0, 25) + '...'
              : event.eventDescription}
          </p>
          <button
            style={{ border: 'none', background: 'none' }}
            onClick={() => navigateToCreateEvent(event.id)}
            className="read-more-btn"
          >
            <IntlMessages id="readmore" />
            <i className="icofont-double-right"></i>
          </button>
        </div>
      </div>
    </>
  )
}
export default ShowEvents
