import React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import IntlMessages from '@/helpers/IntlMessages'
import { BiUser } from 'react-icons/bi'
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined'
import AppAnimate from '@/components/lib/AppAnimate'
import { Fonts } from '@/constants/AppEnums'
import {
  AccountTabsWrapper,
  PersonalInfo,
  BookedUserEvents,
} from '@/modules/lib/account/MyProfile'
import AdminContentView from '@/components/lib/AppContentViewAdmin'
import { IconButton } from '@mui/material'
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined'
import { useNavigate } from 'react-router-dom'

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const tabs = [
  { id: 1, icon: <BiUser />, name: <IntlMessages id="common.personalInfo" /> },
  {
    id: 2,
    icon: <ContactPageOutlinedIcon />,
    name: <IntlMessages id="common.userevents" />,
  },
]

const Account = () => {
  const [value, setValue] = React.useState(0)

  const onTabsChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number
  ) => {
    setValue(newValue)
  }
  const navigate = useNavigate()
  return (
    <AdminContentView>
      <Box component="div" display="flex">
        <AppAnimate animation="transition.slideDownIn" delay={300}>
          <Box
            visibility="visible"
            component="h2"
            mr="10px"
            p="0px"
            sx={{
              fontSize: 16,
              color: 'text.primary',
              fontWeight: Fonts.SEMI_BOLD,
              mb: {
                xs: 2,
                lg: 4,
              },
            }}
          >
            <IconButton
              style={{
                fontSize: '16px',
                color: '#234F1E',
                fontWeight: 600,
                padding: 0,
                background: 'transparent',
              }}
              onClick={() => navigate('/')}
            >
              <ChevronLeftOutlinedIcon style={{ marginTop: '-5px' }} />
              <h2 style={{ marginTop: '-1px' }}>Home</h2>
            </IconButton>
          </Box>
        </AppAnimate>
        <AppAnimate animation="transition.slideDownIn" delay={300}>
          <Box
            component="h2"
            sx={{
              fontSize: 16,
              color: 'text.primary',
              fontWeight: Fonts.SEMI_BOLD,
              mb: {
                xs: 2,
                lg: 4,
              },
            }}
          >
            My Account
          </Box>
        </AppAnimate>
      </Box>
      <AccountTabsWrapper key="2">
        <AppAnimate animation="transition.slideLeftIn" delay={300}>
          <Tabs
            className="account-tabs"
            value={value}
            onChange={onTabsChange}
            aria-label="basic tabs example"
            orientation="vertical"
          >
            {tabs.map((tab, index) => (
              <Tab
                className="account-tab"
                label={tab.name}
                icon={tab.icon}
                key={index}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </AppAnimate>
        <AppAnimate animation="transition.slideRightIn" delay={300}>
          <Box className="account-tabs-content">
            {value === 0 && <PersonalInfo />}
            {value === 1 && <BookedUserEvents />}
          </Box>
        </AppAnimate>
      </AccountTabsWrapper>
    </AdminContentView>
  )
}

export default Account
