import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  auth,
  createUserWithEmailAndPassword,
  facebookAuthProvider,
  githubAuthProvider,
  googleAuthProvider,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
  twitterAuthProvider,
  updateProfile,
} from './firebase'
import { AuthUserType } from '@/types/models/AuthUser'
import jwt_decode, { JwtPayload } from 'jwt-decode'

interface FirebaseContextProps {
  user: AuthUserType | null | undefined
  isAuthenticated: boolean
  isLoading: boolean
  isAdmin?: boolean
  isOrganizer?: boolean
}

interface SignUpProps {
  displayName: string
  email: string
  password: string
}

interface SignInProps {
  email: string
  password: string
}

interface FirebaseActionsProps {
  registerUserWithEmailAndPassword: (data: SignUpProps) => void
  logInWithEmailAndPassword: (data: SignInProps) => void
  logInWithPopup: (type: string) => void
  logout: () => void
}

const FirebaseContext = createContext<FirebaseContextProps>({
  user: null,
  isAuthenticated: false,
  isLoading: true,
  isAdmin: false,
  isOrganizer: false,
})
const FirebaseActionsContext = createContext<FirebaseActionsProps>({
  registerUserWithEmailAndPassword: () => {},
  logInWithEmailAndPassword: () => {},
  logInWithPopup: () => {},
  logout: () => {},
})

export const useFirebase = () => useContext(FirebaseContext)

export const useFirebaseActions = () => useContext(FirebaseActionsContext)

interface FirebaseAuthProviderProps {
  children: ReactNode
  fetchStart: () => void
  fetchSuccess: () => void
  fetchError: (data: string) => void
}

const FirebaseAuthProvider: React.FC<FirebaseAuthProviderProps> = ({
  children,
  fetchStart,
  fetchSuccess,
  fetchError,
}) => {
  const [firebaseData, setFirebaseData] = useState<FirebaseContextProps>({
    user: undefined,
    isLoading: true,
    isAuthenticated: false,
  })

  useEffect(() => {
    fetchStart()
    const unsubscribe = auth.onAuthStateChanged(
      (user) => {
        const firebaseUser = user as AuthUserType
        let isAdmin = false
        let isOrganizer = false
        if (firebaseUser?.accessToken) {
          const decoded = jwt_decode<JwtPayload>(firebaseUser?.accessToken)
          isAdmin = 'admin' in decoded && !!decoded.admin
          isOrganizer = 'organizer' in decoded && !!decoded.organizer
        }
        setFirebaseData({
          user: firebaseUser,
          isAuthenticated: Boolean(user),
          isLoading: false,
          isAdmin: isAdmin,
          isOrganizer: isOrganizer,
        })
        fetchSuccess()
      },
      () => {
        fetchSuccess()
        setFirebaseData({
          user: firebaseData.user,
          isLoading: false,
          isAuthenticated: false,
        })
      },
      () => {
        fetchSuccess()
        setFirebaseData({
          user: firebaseData.user,
          isLoading: false,
          isAuthenticated: true,
        })
      }
    )

    return () => {
      unsubscribe()
    }
  }, [])

  const getProvider = (providerName: string) => {
    switch (providerName) {
      case 'google': {
        return googleAuthProvider
      }
      case 'facebook': {
        return facebookAuthProvider
      }
      case 'twitter': {
        return twitterAuthProvider
      }
      case 'github': {
        return githubAuthProvider
      }
      default:
        return googleAuthProvider
    }
  }

  const logInWithPopup = async (providerName: string) => {
    fetchStart()
    try {
      const { user } = await signInWithPopup(auth, getProvider(providerName))
      setFirebaseData({
        user: user as AuthUserType,
        isAuthenticated: true,
        isLoading: false,
      })
      fetchSuccess()
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } catch ({ message }) {
      setFirebaseData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      })
      fetchError(message as string)
    }
  }

  const logInWithEmailAndPassword = async ({
    email,
    password,
  }: SignInProps) => {
    fetchStart()
    try {
      const { user } = await signInWithEmailAndPassword(auth, email, password)
      setFirebaseData({
        user: user as AuthUserType,
        isAuthenticated: true,
        isLoading: false,
      })
      fetchSuccess()
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } catch ({ message }) {
      setFirebaseData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      })
      fetchError(message as string)
    }
  }
  const registerUserWithEmailAndPassword = async ({
    displayName,
    email,
    password,
  }: SignUpProps) => {
    fetchStart()
    try {
      const { user } = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      )
      const uid = user.uid
      await sendEmailVerification(auth.currentUser!, {
        url: window.location.href,
        handleCodeInApp: true,
      })
      await updateProfile(auth.currentUser!, {
        displayName: displayName,
      })
      setFirebaseData({
        user: { ...user, displayName: displayName } as AuthUserType,
        isAuthenticated: true,
        isLoading: false,
      })
      fetchSuccess()
      return uid as string
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } catch ({ message }) {
      setFirebaseData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      })
      fetchError(message as string)
    }
  }

  const logout = async () => {
    setFirebaseData({ ...firebaseData, isLoading: true })
    try {
      await auth.signOut()
      setFirebaseData({
        user: null,
        isLoading: false,
        isAuthenticated: false,
      })
    } catch (error) {
      setFirebaseData({
        user: null,
        isLoading: false,
        isAuthenticated: false,
      })
    }
  }

  return (
    <FirebaseContext.Provider
      value={{
        ...firebaseData,
      }}
    >
      <FirebaseActionsContext.Provider
        value={{
          logInWithEmailAndPassword,
          registerUserWithEmailAndPassword,
          logInWithPopup,
          logout,
        }}
      >
        {children}
      </FirebaseActionsContext.Provider>
    </FirebaseContext.Provider>
  )
}
export default FirebaseAuthProvider
