import { useAuthUser } from '@/hooks'
import jwt_decode, { JwtPayload } from 'jwt-decode'

export const useAdmin = () => {
  const { tokenUser } = useAuthUser()
  const token = tokenUser?.accessToken
  if (!token) return false
  const decoded = jwt_decode<JwtPayload>(token)

  if ('admin' in decoded && !!decoded.admin) {
    return 'admin'
  } else if ('organizer' in decoded && !!decoded.organizer) {
    return 'organizer'
  } else if ('coorganizer' in decoded && !!decoded.coorganizer) {
    return 'coorganizer'
  } else if ('moderator' in decoded && !!decoded.moderator) {
    return 'moderator'
  }
  return 'user'
}
