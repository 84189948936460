import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import AppContentView from '../../AppContentView'
import AppFixedFooter from './AppFixedFooter'
import AppHeader from './AppHeader'
import AppSidebar from './AppSidebar'
import { useLayoutContext } from '@/context/AppContextProvider/LayoutContextProvider'
import HorHeaderFixedWrapper from './HorHeaderFixedWrapper'
import MainContent from './MainContent'
import { LayoutType } from '@/constants/AppEnums'
import HorHeaderFixedContainer from './HorHeaderFixedContainer'
import { useLocation } from 'react-router-dom'
import { RouterConfigData } from '@/types/models/Apps'

type Props = {
  routes: React.ReactElement | null
  routesConfig: RouterConfigData[]
}
const HorHeaderFixed = ({ routes, routesConfig }: Props) => {
  const { footer, layoutType, footerType } = useLayoutContext()
  const { pathname } = useLocation()
  const [isNavCollapsed, setNavCollapsed] = useState(false)
  const toggleNavCollapsed = () => {
    setNavCollapsed(!isNavCollapsed)
  }
  useEffect(() => {
    if (isNavCollapsed) setNavCollapsed(!isNavCollapsed)
  }, [pathname])

  return (
    <HorHeaderFixedContainer
      className={clsx({
        boxedLayout: layoutType === LayoutType.BOXED,
        framedLayout: layoutType === LayoutType.FRAMED,
      })}
    >
      <HorHeaderFixedWrapper
        className={clsx('horHeaderFixedWrapper', {
          appMainFooter: footer && footerType === 'fluid',
          appMainFixedFooter: footer && footerType === 'fixed',
        })}
      >
        <AppSidebar
          routesConfig={routesConfig}
          isNavCollapsed={isNavCollapsed}
          toggleNavCollapsed={toggleNavCollapsed}
        />

        <MainContent>
          <AppHeader
            toggleNavCollapsed={toggleNavCollapsed}
            routesConfig={routesConfig}
          />
          <AppContentView routes={routes} />
          <AppFixedFooter />
        </MainContent>
      </HorHeaderFixedWrapper>
    </HorHeaderFixedContainer>
  )
}

export default HorHeaderFixed
