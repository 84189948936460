import { enUS } from '@mui/material/locale'
import enMessages from '../locales/en_US.json'

const EnLang = {
  messages: {
    ...enMessages,
  },
  muiLocale: enUS,
  locale: 'en-US',
}
export default EnLang
