import { RoutePermittedRole } from '@/constants/AppEnums'
import Account from '../../modules/account/myProfile'

export const accountPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.User,
    path: '/my-account',
    element: <Account />,
  },
]
