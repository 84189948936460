import { useInfoViewActionsContext } from '@/context/AppContextProvider/InfoViewContextProvider'
import {
  BookEvent,
  BookEventUpdate,
} from '~/modules/adminPages/@types/bookEvent'
import { apiHelperFunctions } from './apiHelperFunctions'
import { useAuthUser } from '../AuthHooks'
import { FirebaseError } from 'firebase/app'
import { ConsumerEventType } from '~/modules/adminPages/@types/app'

export const useBookEvent = () => {
  const { fetchStart, fetchSuccess, fetchError, showMessage } =
    useInfoViewActionsContext()
  const { apiUnwrap, api } = apiHelperFunctions()

  const { tokenUser } = useAuthUser()
  const token = tokenUser?.accessToken

  async function bookEvent(d: any): Promise<BookEvent> {
    try {
      fetchStart()
      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({
          userId: d.userId,
          eventId: d.eventId,
          person: d.person,
          isPresent: d.isPresent,
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
      fetchSuccess()
      showMessage('Success')
      const data = await apiUnwrap<BookEvent>(
        `${process.env.REACT_APP_API_URL}/bookevent`,
        requestOptions
      )

      return data
    } catch (error) {
      if (error instanceof FirebaseError) {
        fetchError(error.code)
        throw error
      } else {
        fetchError('An error occurred')
        throw error
      }
    }
  }

  async function getBookEventId(id: string): Promise<BookEvent[]> {
    try {
      fetchStart()
      const requestOptions: RequestInit = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
      const data = await api<BookEvent[]>(
        `${process.env.REACT_APP_API_URL}/bookevent-eventid/${id}`,
        requestOptions
      )
      fetchSuccess()
      return data
    } catch (error) {
      if (error instanceof FirebaseError) {
        fetchError(error.code)
        throw error
      } else {
        fetchError('An error occurred')
        throw error
      }
    }
  }

  async function isBooked(
    userId: string | undefined,
    eventId: string | undefined
  ): Promise<boolean> {
    try {
      fetchStart()
      const requestOptions: RequestInit = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
      const data = await api<boolean>(
        `${process.env.REACT_APP_API_URL}/getbookeventbyuserid/${userId}/${eventId}`,
        requestOptions
      )
      fetchSuccess()
      return data
    } catch (error) {
      if (error instanceof FirebaseError) {
        fetchError(error.code)
        throw error
      } else {
        fetchError('An error occurred')
        throw error
      }
    }
  }

  async function getEventName(): Promise<Event[]> {
    try {
      fetchStart()
      const requestOptions: RequestInit = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
      const data = await api<Event[]>(
        `${process.env.REACT_APP_API_URL}/unique-event`,
        requestOptions
      )
      fetchSuccess()
      return data
    } catch (error) {
      if (error instanceof FirebaseError) {
        fetchError(error.code)
        throw error
      } else {
        fetchError('An error occurred')
        throw error
      }
    }
  }

  async function getEventByUserId(id: any): Promise<ConsumerEventType[]> {
    try {
      fetchStart()
      const requestOptions: RequestInit = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
      const data = await api<ConsumerEventType[]>(
        `${process.env.REACT_APP_API_URL}/bookevent-userId/${id}`,
        requestOptions
      )
      fetchSuccess()
      return data
    } catch (error) {
      if (error instanceof FirebaseError) {
        fetchError(error.code)
        throw error
      } else {
        fetchError('An error occurred')
        throw error
      }
    }
  }

  async function updateBookEvent(d: any): Promise<BookEventUpdate> {
    try {
      fetchStart()
      const jsonData = {
        eventId: d.eventId,
        isPresent: d.isPresent,
      }
      const requestOptions: RequestInit = {
        method: 'PUT',
        body: JSON.stringify(jsonData),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
      fetchSuccess()
      showMessage('Success')

      const data = await apiUnwrap<BookEventUpdate>(
        `${process.env.REACT_APP_API_URL}/update-bookevent`,
        requestOptions
      )
      return data
    } catch (error) {
      if (error instanceof FirebaseError) {
        fetchError(error.code)
        throw error
      } else {
        fetchError('An error occurred')
        throw error
      }
    }
  }

  return {
    bookEvent,
    getBookEventId,
    updateBookEvent,
    getEventName,
    getEventByUserId,
    isBooked,
  }
}
