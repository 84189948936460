import React, { ReactNode } from 'react'
import AppLoader from '../AppLoader'
import LazyLoad from './LazyLoad'

type AppSuspenseProps = {
  children: ReactNode
}

const AppSuspense: React.FC<AppSuspenseProps> = ({ children }) => {
  return (
    <React.Suspense
      fallback={
        <>
          <LazyLoad />
          <AppLoader />
        </>
      }
    >
      {children}
    </React.Suspense>
  )
}

export default AppSuspense
